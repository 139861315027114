import React, { useMemo } from "react";
import _ from "lodash";

import Player from '../Player';
import FullscreenWrapper from '../FullscreenWrapper';

import { navigate } from "gatsby-link";

import { getEntityUrl } from "../../util";

const Videoplayer = (props) => {
    const { component, entity, files, pageContext, fullscreenMode, } = props;
    const { defaultLocale } = pageContext;

    const videoUrl = useMemo(() => {
        return _.find(files.nodes, { id: component.video?.localFile___NODE })?.publicURL;
    }, [files, component]);
    
    const onEnded = () => {
        if (component.continueTo) navigate(getEntityUrl({ entity: component.continueTo, defaultLocale}));//TODO - locale working?
    }
    //TODO - hide "fullscreen" button in app
    return <FullscreenWrapper {...props} hideButton={false}>{
        videoUrl !== undefined &&
        <Player width={fullscreenMode ? '100vw' : '100%'} height={fullscreenMode ? '100vh' : '100%'}
            url={videoUrl}
            controls={true}
            stopOnUnmount={false}
            playing={component.autoplay || false}
            playsinline={!component.fullscreen || false}
            onEnded={onEnded}
            isVideo={true}
            fullscreenMode={props.fullscreenMode}
        />
    }
    </FullscreenWrapper>
};

export default Videoplayer;