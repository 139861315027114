import React from 'react';
import { styled } from '@mui/material/styles';

import { IconButton } from '@mui/material';
import FiberManualRecord from '@mui/icons-material/FiberManualRecord';

import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';

const StyledFiberManualRecordIcon = styled(FiberManualRecord)({
    fontSize: "15px",
});

const StyledIndicatorIconButton = styled(
    IconButton,
    { shouldForwardProp: (propName) => !propName.startsWith('$') }
)(({ $active }) => ({
    cursor: "pointer",
    transition: "200ms",
    padding: 0,
    color: $active ? "#494949" : "#afafaf",
    '&:hover': {
        color: $active ? "#494949" : "#1f1f1f",
    },
    '&:active': {
        color: $active ? "#494949" : "#1f1f1f",
    }
}));


export const CaptionDiv = styled('div')(({ theme }) => ({
    position: 'absolute',
    bottom: '0px',
    zIndex: 65535,
    color: '#fff',
    width: '100%',
    overflowWrap: 'break-word',
    background: '#000a',
}));

const Carousel = ({carouselProps, className, fullscreenMode, children, hasCaption}) => {

    const indicator = (onClick, isSelected, index, label) => {
        return <StyledIndicatorIconButton
            $active={isSelected}
            key={index}
            onClick={onClick}
            style={{
                marginTop: fullscreenMode ? '-25px' : hasCaption ? '-25px' : '4px',
            }}
        >
            <StyledFiberManualRecordIcon />
        </StyledIndicatorIconButton>
    }

    return <ResponsiveCarousel {...carouselProps} className={className} renderIndicator={indicator} >
        {children}
    </ResponsiveCarousel>

}

export default Carousel;