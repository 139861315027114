import React, { useMemo, useState, useEffect, useCallback } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { styled } from '@mui/material/styles';


import _ from "lodash";
import Player from '../Player';
import FullscreenWrapper from '../FullscreenWrapper';

import { getImage, GatsbyImage } from "gatsby-plugin-image";

import { Box, } from "@mui/material";

import Carousel, { CaptionDiv } from '../Carousel';
import { getConfig } from "react-pannellum";


const PREFIX = 'Imageslider';

const classes = {
    playerFullscreen: `${PREFIX}-playerFullscreen`
};

const Root = styled('div')(() => ({
    marginBottom: '6px',

    [`& .${classes.playerFullscreen}`]: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
    }
}));

const Imageslider = (props) => {
    const { component, files, fullscreenMode } = props;

    const images = useMemo(() => {
        const images = [];
        _.each(component.images, i => images.push(_.find(files.nodes, { id: i.localFile___NODE })));
        return images;
    }, [files, component]);

    const audioUrl = useMemo(() => {
        return _.find(files.nodes, { id: component.audio?.localFile___NODE })?.publicURL;
    }, [files, component]);

    component.duration = parseInt(component.duration);

    const carouselProps = {
        infiniteLoop: true,
        interval: (component.duration || 3) * 1000,
        autoPlay: component.duration !== 0,
        showThumbs: false,
        showStatus: false,
        swipeScrollTolerance: 50,
        swipeable: component.zoomable !== true,
    };

    const [playerStyle, setPlayerStyle] = useState('');
    useEffect(() => {
        setPlayerStyle(fullscreenMode ? classes.playerFullscreen : '')
    }, [fullscreenMode]);

    const hasCaption = component.images?.some(i => i.caption !== '');

    const ImageWrapper = ({ children }) => {
        if (component.zoomable === true) {
            return (
                <TransformWrapper>
                    <TransformComponent>{children}
                    </TransformComponent>
                </TransformWrapper>
            );
        } else {
            return <>{children}</>
        }
    };

    return (<Root>
        <FullscreenWrapper {...props} >
            {
                images.length > 0 && (
                    images.length === 1 ?
                        <div className="imageslider">
                            <ImageWrapper>
                                <GatsbyImage image={getImage(images[0])} alt="" objectFit="contain" loading="eager" imgStyle={{ position: 'static', maxHeight: '100vh' }} />
                            </ImageWrapper>
                        </div>
                        :
                        <Carousel carouselProps={carouselProps} className="imageslider" hasCaption={hasCaption} fullscreenMode={fullscreenMode}>
                            {images.map((i, n) =>
                                <Box key={i.id} align="center">
                                    <ImageWrapper>
                                        <GatsbyImage image={getImage(i)} style={{ width: '100%' }} imgStyle={{ position: 'static', maxHeight: '100vh' }} alt="" loading={n < 10 ? 'eager' : 'lazy'} objectFit="contain" position="static" />
                                    </ImageWrapper>
                                    {component.images[n].caption !== '' &&
                                        <CaptionDiv>{component.images[n].caption}</CaptionDiv>}

                                </Box>
                            )}
                        </Carousel>
                )
            }
            {
                audioUrl !== undefined &&
                <div className={playerStyle}>
                    <Player style={{ paddingTop: '12px' }} width='100%' height='42px'
                        url={audioUrl}
                        controls={true}
                        loop={true /*TODO - discuss*/}
                        playing={component.autoplayAudio || false}
                        playsinline={component.fullscreen || false}
                    />
                </div>
            }

        </FullscreenWrapper >
    </Root >
    );
}

export default Imageslider;