import React, { useState, useEffect, useCallback } from "react";
import { styled } from '@mui/material/styles';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import FullScreenIcon from "@mui/icons-material/Fullscreen";
import CloseFullScreenIcon from "@mui/icons-material/FullscreenExit";

import { alpha } from "@mui/material/styles";

const PREFIX = 'FullScreenWrapper';

const classes = {
    fullscreen: `${PREFIX}-fullscreen`,
    centeredContent: `${PREFIX}-centeredContent`,
    fullScreenButton: `${PREFIX}-fullScreenButton`,
    fullScreenButtonFull: `${PREFIX}-fullScreenButtonFull`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.fullscreen}`]: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 65530,
        backgroundColor: '#000',
        maxHeight: '100vh',
    },

    [`& .${classes.centeredContent}`]: {
        marginTop: '50vh',
        transform: 'translateY(-50%)',
        position: 'relative',
        overflowY: 'hidden',
    },

    [`& .${classes.fullScreenButton}`]: {
        position: 'absolute',
        right: 0,
        bottom: '7px',
        zIndex: 65520,
        color: '#ccc',
    },

    [`& .${classes.fullScreenButtonFull}`]: {
        bottom: 0,
        color: '#fff',
        backgroundColor: alpha('#000', .3),
    }
}));

const ENABLE_NATIVE_FULLSCREEN_API = false;

const FullScreenWrapper = React.forwardRef(({ component, fullscreenMode, setFullscreenMode, children, hideButton, buttonStyle }, ref) => {
    const [isFullscreen, setIsFullscreen] = useState(fullscreenMode || false);

    const fullscreenToggle = useCallback(() => {
        setIsFullscreen(!isFullscreen);
    }, [setIsFullscreen, isFullscreen]);

    useEffect(() => {
        setFullscreenMode(isFullscreen);
    }, [isFullscreen]);

    const fullscreenHandle = useFullScreenHandle();//TODO - remove if fullscreen-api not used
    useEffect(() => {
        if (ENABLE_NATIVE_FULLSCREEN_API && document.fullscreenEnabled) {
            if (isFullscreen) fullscreenHandle.enter();
            else fullscreenHandle.exit();
        }
    }, [isFullscreen]);

    const [FullscreenButton, setFullscreenButton] = useState(FullScreenIcon);
    useEffect(() => {
        if (component.fullscreen) {
            setFullscreenButton('div');
        } else {
            if (isFullscreen) {
                setFullscreenButton(CloseFullScreenIcon);
                document.body.style.overflow = "hidden";
                window.scrollTo(0, 0);
            } else {
                setFullscreenButton(FullScreenIcon);
                document.body.style.overflow = "auto";
            }
        }
    }, [isFullscreen]);

    const FullscreenElement = ENABLE_NATIVE_FULLSCREEN_API ? FullScreen : 'div';//isFullscreen ? 'div' : 'div';//TODO - fullscreen api?



    const fullscreenElementProps = ENABLE_NATIVE_FULLSCREEN_API ?
        {
            handle: fullscreenHandle,
            fullscreen: isFullscreen,
            open: true,
            className: isFullscreen ? `${classes.fullscreen}` : ''

        }
        :
        {
            className: isFullscreen ? `${classes.fullscreen}` : ''
        };

    return (<Root>
        <FullscreenElement {...fullscreenElementProps} ref={ref}>
            <div className={isFullscreen ? classes.centeredContent : ''}>
                {children}
                <div style={{ position: 'relative' }}>
                    {hideButton !== true &&
                        <FullscreenButton onClick={fullscreenToggle} style={buttonStyle}
                            className={`${classes.fullScreenButton} ${isFullscreen ? classes.fullScreenButtonFull : ''}`} />
                    }
                </div>
            </div>
        </FullscreenElement>
        </Root>
    );
});

export default FullScreenWrapper;