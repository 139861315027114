import React, { useMemo, useCallback, useState, useEffect } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { styled } from '@mui/material/styles';
import _ from "lodash";
import Player from '../Player';
import FullscreenWrapper from '../FullscreenWrapper';

import { getImage, GatsbyImage } from "gatsby-plugin-image";

import { Box, } from "@mui/material";
import Carousel, { CaptionDiv } from '../Carousel';

import { navigate } from "gatsby-link";

import { getEntityUrl } from "../../util";

const PREFIX = 'Imageplayer';

const classes = {
    playerFullscreen: `${PREFIX}-playerFullscreen`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    marginBottom: '6px',

    [`& .${classes.playerFullscreen}`]: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
    }
}));

const Imageplayer = (props) => {
    const { component, entity, files, pageContext, fullscreenMode } = props;

    const [images, timestamps] = useMemo(() => {
        const images = {};
        _.each(component.image,
            ({ image, timestamp, caption }) => {
                const key = parseInt(timestamp || 0);

                images[key] = {
                    localFile: _.find(files.nodes, { id: image?.localFile___NODE }),
                    timestamp: key,
                    caption: image?.caption,
                }
            });

        const sortedKeys = Object.keys(images).sort((a, b) => a - b);

        return [images, sortedKeys];
    }, [files, component]);

    const audioUrl = useMemo(() => {
        return _.find(files.nodes, { id: component.audio?.localFile___NODE })?.publicURL;
    }, [files, component]);


    const [currentTimestamp, setCurTimeIndex] = useState(0);
    const onProgress = useCallback(({ playedSeconds, type, target, ...props }) => {
        let seconds = playedSeconds;
        if (type === 'timeupdate') seconds = target.currentTime;

        const playedMs = parseInt(seconds * 1000);

        const selTime = timestamps.reduce((prev, curr) => {
            return playedMs > curr ? curr : prev
        });

        setCurTimeIndex(timestamps.findIndex(e => e === selTime));
    }, [setCurTimeIndex, timestamps]);

    const { defaultLocale } = pageContext;
    const onEnded = () => {
        if (component.continueTo) navigate(getEntityUrl({ entity: component.continueTo, defaultLocale }));
    }
    const carouselProps = {
        infiniteLoop: true,
        showThumbs: false,
        showStatus: false,
        selectedItem: currentTimestamp,
        showIndicators: false,
        showArrows: false,
        swipeable: false,
    };


    const [playerStyle, setPlayerStyle] = useState('');
    useEffect(() => {
        setPlayerStyle(fullscreenMode ? classes.playerFullscreen : '')
    }, [fullscreenMode]);

    const hasCaption = useMemo(() => { return images !== undefined && _.some(images, i => i.caption !== '') }, [images]);

    const ImageWrapper = ({ children }) => {
        if (component.zoomable === true) {
            return (
                <TransformWrapper>
                    <TransformComponent>{children}
                    </TransformComponent>
                </TransformWrapper>
            );
        } else {
            return <>{children}</>
        }
    };
    return (
        <Root>
            <FullscreenWrapper {...props}> {
                timestamps.length > 0 && (
                    timestamps.length === 1 ?
                        <ImageWrapper>
                            <GatsbyImage image={getImage(images[timestamps[0]].localFile)} alt="" />
                        </ImageWrapper>
                        :
                        <Carousel carouselProps={carouselProps} className="imageplayer" hasCaption={hasCaption} fullscreenMode={fullscreenMode} >
                            {timestamps.map(k =>
                                <Box align="center" key={k}>
                                    <ImageWrapper>
                                        <GatsbyImage image={getImage(images[k].localFile)} style={{ width: '100%' }} imgStyle={{ position: 'static', maxHeight: '100vh' }} alt="" loading={k === 0 ? 'eager' : 'lazy'} objectFit="contain" position="static" />
                                    </ImageWrapper>
                                    {images[k].caption !== '' &&
                                        <CaptionDiv>{images[k].caption}</CaptionDiv>}
                                </Box>
                            )}
                        </Carousel>
                )}
                {audioUrl !== undefined &&
                    <div className={playerStyle}>
                        <Player style={{ paddingTop: 12 }} width='100%' height='42px'
                            url={audioUrl}
                            controls={true}
                            loop={component.loop || false}
                            playing={component.autoplay || false}
                            playsinline={component.fullscreen || false}
                            onProgress={onProgress}
                            onEnded={onEnded}
                        />
                    </div>
                }
            </FullscreenWrapper>
        </Root>
    );
}

export default Imageplayer;